import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from "react-router-dom";
import axios from "../../axios";
import Loader from "../../Components/Loader";
import { ToastContainer, toast } from "react-toastify";
import Page1 from './Page1';
import Page2 from './Page2';
import OpenTickets from '../OpenTickets';
import ReactCountryFlag from "react-country-flag"
import DefaultLogo from "../../Images/foxilogic-disable.png"
import { MINDATE, MAXDATE } from '../../Custom';
import { CUSTOM_CLIENT_SELECT } from '../../Custom';

const OutageList = () => {
  const navigate = useNavigate();

  var url = new URL(window.location.href);
  const search = url.searchParams.get('Search') || ''
  const limit = url.searchParams.get('Limit') || 500
  const tab = url.searchParams.get('tab') || 'Page2'
  const clientid = url.searchParams.get('ClientID') || ''
  const countryid = url.searchParams.get('CountryID') || ''

  var today = new Date();
  var day = today.getDate();
  var month = today.getMonth() + 1;
  var year = today.getFullYear();

  // This arrangement can be altered based on how we want the date's format to appear.
  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }

  const CurrentDate = `${year}-${month}-${day}`;
  const selected_date = url.searchParams.get('Date') || CurrentDate

  const [page, setPage] = useState(url.searchParams.get('PageNo') || 1);

  const [loader, setLoader] = useState(false);
  const UserGroup = JSON.parse(localStorage.getItem("UserGroup"));
  const [NoOfRecords, setNoOfRecords] = useState(0);
  const [ClientID, setClientID] = useState(localStorage.getItem("ClientId") > 0 ? localStorage.getItem("ClientId") : clientid)
  const [ClientList, setClientList] = useState([])
  const [CountryID, setCountryID] = useState(localStorage.getItem("ClientCountryId") > 0 ? localStorage.getItem("ClientCountryId") : countryid)
  const [CountryList, setCountryList] = useState([])
  const [ChannelPartners, setChannelPartners] = useState([])
  const [ChannelPartnersSelected, setChannelPartnersSelected] = useState(localStorage.getItem("ChannelPartnerName") !== "" ? localStorage.getItem("ChannelPartnerName").slice(1, -1) : "")
  const [DeviceTypesGroups, setDeviceTypesGroups] = useState([])
  const [DeviceTypesGroupsSelected, setDeviceTypesGroupsSelected] = useState("")
  const [DeviceTypes, setDeviceTypes] = useState([])

  const [DeviceTypesSelected, setDeviceTypesSelected] = useState('')
  const [Regions, setRegions] = useState([])
  const [RegionsSelected, setRegionsSelected] = useState('')
  const [Page1Data, setPage1Data] = useState([])
  const [TotalImpactedBasestations, setTotalImpactedBasestations] = useState('')
  const [TotalImpactedDevices, setTotalImpactedDevices] = useState('')
  const [TotalImpactedSites, setTotalImpactedSites] = useState('')

  // page 2
  const [OutageData, setOutageData] = useState([])
  const [SiteWiseCounts, setSiteWiseCounts] = useState([])
  const [TotalCount, setTotalCount] = useState([])

  const [TotalOtherCount, setTotalOtherCount] = useState([])
  const [TotalStationaryCount, setTotalStationaryCount] = useState([])

  const [success, setSuccess] = useState("");
  const [ActiveTab, setActiveTab] = useState(tab);

  const [ServiceCall, setServiceCall] = useState(false);
  const [searchInput, setSearchInput] = useState(search)

  const [DevicesPopData, setDevicesPopData] = useState([])
  const [Filter, setFilter] = useState('')
  const [SiteSelected, setSiteSelected] = useState('')
  const [Count, setCount] = useState(0);
  const [TicketsModuleAccess, setTicketsModuleAccess] = useState(false);
  const [SiteName, setSiteName] = useState("")

  const [MapData, setMapData] = useState([])
  const [BaseStationIdNavigation, setBaseStationIdNavigation] = useState("");

  const [SelectedDate, setSelectedDate] = useState(selected_date);

  useMemo(() => {
    setPage(url.searchParams.get('PageNo') || 1);
  }, [url.searchParams.get('PageNo'), RegionsSelected])

  useEffect(() => {
    const handle = setInterval(() => {
      setCount(Count + 1);
    }, 900000);

    return () => {
      clearInterval(handle);
    };
  }, [Count]);

  useEffect(() => {
    if (success) {
      toast.success(success);
      setSuccess("");
    }
    return () => { };
  }, [success]);

  useEffect(() => {

    for (var i = 0; i < ClientList.length; i++) {
      if (ClientList[i].Id === parseInt(ClientID)) {
        setCountryList(ClientList[i].countries)
        break
      }
    }
  }, [ClientID, ClientList]);

  useEffect(() => {
    if (UserGroup.Id === 1 || UserGroup.Id === 2 || UserGroup.Id === 3) {
      setLoader(true)
      axios.get("get_clients")
        .then((response) => {
          var TempData = response.data.data;
          var arr = [];
          for (var i = 0; i < TempData.length; i++) {
            var obj = TempData[i]
            obj["label"] = TempData[i].Name;
            obj["value"] = TempData[i].Id;
            obj["image"] = TempData[i].ClientLogo ? TempData[i].ClientLogo : DefaultLogo;
            arr.push(obj)
          }
          setClientList(arr)
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
        })
    }
  }, [UserGroup.Id])
  useEffect(() => {
    if (CountryID) {
      if (ChannelPartnersSelected !== "Fidelity ADT" && ChannelPartnersSelected !== "") {
        setDeviceTypesGroupsSelected("Other");
      }

      setLoader(true)
      axios.get("get_outage_mngt_filters?ClientCountryId=" + CountryID + "&ChannelPartner=" + ChannelPartnersSelected + "&Date=" + SelectedDate)
        .then((response) => {
          setDeviceTypesGroups(response.data.data.DeviceTypesGroups)
          if (DeviceTypesGroupsSelected === "Other") {
            setDeviceTypes(response.data.data.OtherDeviceTypes);
          } else if (DeviceTypesGroupsSelected === "Stationary") {
            setDeviceTypes(response.data.data.StationaryDeviceTypes)
          }
          // setRegions(response.data.data.Regions)
          setChannelPartners(response.data.data.ChannelPartners)
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
        })
    }
  }, [CountryID, ChannelPartnersSelected, DeviceTypesGroupsSelected, SelectedDate])

  useEffect(() => {
    if (CountryID) {
      if (ChannelPartnersSelected !== "Fidelity ADT" && ChannelPartnersSelected !== "") {
        setDeviceTypesGroupsSelected("Other");
      }

      setLoader(true)
      axios.get("masters?ClientCountryId=" + CountryID)
        .then((response) => {
          setRegions(response.data.data.SigfoxSiteRegions)
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
        })
    }
  }, [CountryID])

  useEffect(() => {
    if (ServiceCall && ClientID && CountryID && ActiveTab === 'Page1') {
      setLoader(true)
      setPage1Data([]);
      setTotalImpactedBasestations("")
      setTotalImpactedDevices("")
      setTotalImpactedSites("")
      axios.get("get_impacted_devices_end_customers?page=1&tab=" + ActiveTab + "&search=" + searchInput + "&ClientCountryId=" + CountryID + "&PageNo=" + page + "&Limit=" + limit + "&Regions=" + RegionsSelected.toString() + "&DeviceTypes=" + DeviceTypesGroupsSelected + (DeviceTypesSelected !== '' ? ',' + DeviceTypesSelected.toString() : '') + "&ChannelPartner=" + ChannelPartnersSelected + "&Date=" + SelectedDate)
        .then((response) => {
          setPage1Data(response.data.data.OutageData)
          setTotalImpactedDevices(response.data.data.TotalImpactedDevices);
          setTotalImpactedBasestations(response.data.data.TotalImpactedBasestations);
          setNoOfRecords(response.data.NoOfRecords);
          setTotalImpactedSites(response.data.data.TotalImpactedSites)

          setLoader(false)

        })
        .catch((error) => {
          setLoader(false)
        })
    }
  }, [Count, ServiceCall, ClientID, CountryID, ActiveTab, searchInput, RegionsSelected, DeviceTypesGroupsSelected, DeviceTypesSelected, ChannelPartnersSelected, page, limit, navigate, SelectedDate])

  const ExportEndCustomerDevices = () => {
    setLoader(true)
    axios.get("export_end_customer_devices?page=1&tab=" + ActiveTab + "&search=" + searchInput + "&ClientCountryId=" + CountryID + "&PageNo=" + page + "&Limit=" + limit + "&Regions=" + RegionsSelected.toString() + "&DeviceTypes=" + DeviceTypesGroupsSelected + (DeviceTypesSelected !== '' ? ',' + DeviceTypesSelected.toString() : '') + "&ChannelPartner=" + ChannelPartnersSelected + "&Date=" + SelectedDate)
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false)
        setSuccess(response.data.status.message)
      })
      .catch((error) => {
        setLoader(false)
      })
  }

  const clearSearchHandler = () => {
    setServiceCall(true)
    setSearchInput("")
    setRegionsSelected('')
    setDeviceTypesGroupsSelected('')
    setDeviceTypesSelected('')
    setChannelPartnersSelected(localStorage.getItem("ChannelPartnerName") !== "" ? localStorage.getItem("ChannelPartnerName").slice(1, -1) : "")
    navigate("?tab=" + ActiveTab + "&PageNo=" + page + "&Limit=" + limit + "&ClientID=" + ClientID + "&CountryID=" + CountryID + "&Search=" + searchInput + "&Regions=" + RegionsSelected.toString() + "&DeviceTypes=" + DeviceTypesGroupsSelected + (DeviceTypesSelected !== '' ? ',' + DeviceTypesSelected.toString() : '') + "&ChannelPartner=" + ChannelPartnersSelected)
  }

  useEffect(() => {
    navigate("?tab=" + ActiveTab + "&PageNo=" + page + "&Limit=" + limit + "&ClientID=" + ClientID + "&CountryID=" + CountryID + "&Search=" + searchInput + "&Regions=" + RegionsSelected.toString() + "&DeviceTypes=" + DeviceTypesGroupsSelected + (DeviceTypesSelected !== '' ? ',' + DeviceTypesSelected.toString() : '') + "&ChannelPartner=" + ChannelPartnersSelected + "&Date=" + SelectedDate)
    setServiceCall(true);
  }, [navigate, ActiveTab, page, limit, ClientID, CountryID, searchInput, RegionsSelected, DeviceTypesGroupsSelected, DeviceTypesSelected, ChannelPartnersSelected, SelectedDate])

  const HandleRigion = (item) => {
    const RegionsSelectedTemp = JSON.parse(JSON.stringify(RegionsSelected))
    if (RegionsSelectedTemp.includes(item)) {
      RegionsSelectedTemp.splice(RegionsSelectedTemp.indexOf(item), 1)
    } else {
      RegionsSelectedTemp.push(item)
    }
    setRegionsSelected(RegionsSelectedTemp)
  }

  const HandleDeviceTypes = (item) => {
    const DeviceTypesSelectedTemp = JSON.parse(JSON.stringify(DeviceTypesSelected))
    if (DeviceTypesSelectedTemp.includes(item)) {
      DeviceTypesSelectedTemp.splice(DeviceTypesSelectedTemp.indexOf(item), 1)
    } else {
      DeviceTypesSelectedTemp.push(item)
    }
    setDeviceTypesSelected(DeviceTypesSelectedTemp)
  }

  useEffect(() => {
    if (ServiceCall && ClientID && CountryID && ActiveTab === 'Page2') {
      setLoader(true)
      setSiteWiseCounts([]);
      setOutageData([]);
      setTotalImpactedBasestations("")
      setTotalImpactedDevices("")
      setTotalImpactedSites("")

      axios.get("get_impacted_devices_internal?page=1&tab=" + ActiveTab + "&search=" + searchInput + "&ClientCountryId=" + CountryID + "&PageNo=" + page + "&Limit=" + limit + "&Regions=" + RegionsSelected.toString() + "&DeviceTypes=" + DeviceTypesGroupsSelected + (DeviceTypesSelected !== '' ? ',' + DeviceTypesSelected.toString() : '') + "&ChannelPartner=" + ChannelPartnersSelected + "&Date=" + SelectedDate)
        .then((response) => {
          setOutageData(response.data.data.OutageData)
          setSiteWiseCounts(response.data.data.SiteWiseCounts)
          setTotalCount(response.data.data.TotalCount)
          setTotalImpactedBasestations(response.data.data.TotalImpactedBasestations)
          setTotalImpactedDevices(response.data.data.TotalImpactedDevices)
          setTotalOtherCount(response.data.data.TotalOtherCount)
          setTotalStationaryCount(response.data.data.TotalStationaryCount)
          setTotalImpactedSites(response.data.data.TotalImpactedSites)
          setTicketsModuleAccess(response.data.data.TicketsModuleAccess)
          setLoader(false)
        })
        .catch((error) => {
          setLoader(false)
        })
    }
  }, [Count, ServiceCall, ClientID, CountryID, ActiveTab, searchInput, RegionsSelected, DeviceTypesGroupsSelected, DeviceTypesSelected, ChannelPartnersSelected, page, limit, navigate, SelectedDate])

  useEffect(() => {
    if (ServiceCall && ClientID && CountryID && ActiveTab === 'Page2') {
      setTicketsModuleAccess(false)
      setLoader(true)
      setMapData([]);

      axios.get("get_impacted_devices_internal_outagedata?page=1&tab=" + ActiveTab + "&search=" + searchInput + "&ClientCountryId=" + CountryID + "&PageNo=" + page + "&Limit=" + limit + "&Regions=" + RegionsSelected.toString() + "&DeviceTypes=" + DeviceTypesGroupsSelected + (DeviceTypesSelected !== '' ? ',' + DeviceTypesSelected.toString() : '') + "&ChannelPartner=" + ChannelPartnersSelected + "&Date=" + SelectedDate)
        .then((response) => {
          setMapData(response.data.data.OutageData);
          setTicketsModuleAccess(response.data.data.TicketsModuleAccess)
          setLoader(false)
        })
        .catch((error) => {
          setLoader(false)
        })
    }
  }, [Count, ServiceCall, ClientID, CountryID, ActiveTab, searchInput, RegionsSelected, DeviceTypesGroupsSelected, DeviceTypesSelected, ChannelPartnersSelected, page, limit, navigate, SelectedDate])

  const ExportInternalDevices = () => {
    setLoader(true)
    let url = "export_internal_devices?page=2&tab=" + ActiveTab + "&search=" + searchInput + "&ClientCountryId=" + CountryID + "&PageNo=" + page + "&Limit=" + limit + "&Regions=" + RegionsSelected.toString() + "&DeviceTypes=" + DeviceTypesGroupsSelected + (DeviceTypesSelected !== '' ? ',' + DeviceTypesSelected.toString() : '') + "&ChannelPartner=" + ChannelPartnersSelected + "&Date=" + SelectedDate

    axios.get(url)
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false)
        setSuccess(response.data.status.message)
      })
      .catch((error) => {
        setLoader(false)
      })
  }

  const ExportInternalDevicesDt = (Site = '', ExportType = '') => {
    setLoader(true)
    let url = "export_internal_devices_by_dt_group?page=2&tab=" + ActiveTab + "&search=" + searchInput + "&ClientCountryId=" + CountryID + "&PageNo=" + page + "&Limit=" + limit + "&Regions=" + RegionsSelected.toString() + "&DeviceTypes=" + DeviceTypesGroupsSelected + (DeviceTypesSelected !== '' ? ',' + DeviceTypesSelected.toString() : '') + "&ChannelPartner=" + ChannelPartnersSelected + "&Date=" + SelectedDate
    if (Site !== '') {
      url += "&Site=" + Site
    }
    if (ExportType !== '') {
      url += "&ExportType=" + ExportType
    }

    axios.get(url)
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false)
        setSuccess(response.data.status.message)
      })
      .catch((error) => {
        setLoader(false)
      })
  }

  const FetchDevicesPopData = (Site, Filter = '') => {
    setLoader(true)
    axios.get("get_impacted_devices_internal_list_for_site?page=1&tab=" + ActiveTab + "&search=" + searchInput + "&ClientCountryId=" + CountryID + "&PageNo=" + page + "&Limit=" + limit + "&Regions=" + RegionsSelected.toString() + "&DeviceTypes=" + DeviceTypesGroupsSelected + (DeviceTypesSelected !== '' ? ',' + DeviceTypesSelected.toString() : '') + "&ChannelPartner=" + ChannelPartnersSelected + "&Site=" + Site + "&Date=" + SelectedDate)
      .then((response) => {
        const AllData = []
        for (var i = 0; i < response.data.data.length; i++) {
          if (Filter === '') {
            AllData.push(response.data.data[i])
          }

          if (Filter === 'Other' && response.data.data[i].DeviceTypeGroup === 'Other') {
            AllData.push(response.data.data[i])
          }

          if (Filter === 'Stationary' && response.data.data[i].DeviceTypeGroup === 'Stationary') {
            AllData.push(response.data.data[i])
          }
        }

        setFilter(Filter)
        setSiteSelected(Site)
        setDevicesPopData(AllData)
        setLoader(false)
        setServiceCall(false)
      })
      .catch((error) => {
        setLoader(false)
      })
  }

  const getClientValue = () => {
    if (ClientID) {
      for (var i = 0; i < ClientList.length; i++) {
        if (ClientList[i].Id === parseInt(ClientID)) {
          return [{ value: ClientList[i].Id, label: ClientList[i].Name, image: ClientList[i].image }];
        }
      }
    }
  }
  const OnchangeClient = (selected) => {
    setCountryID('')
    setClientID(selected.Id)
  }

  return (
    <>
      <ToastContainer theme="colored" />
      {loader ? <Loader /> : null}
      {SiteName !== "" ? <OpenTickets SiteName={SiteName} setSiteName={setSiteName} ClientCountryId={CountryID} Identify="OutageMngtSite" /> : null}
      {
        BaseStationIdNavigation !== "" ?
          <OpenTickets BaseStationId={BaseStationIdNavigation} Identify={"DashboardMap"} setBaseStationId={setBaseStationIdNavigation} ClientCountryId={CountryID} />
          : null
      }

      <div>
        <div className="row">
          <div className="col-md-8">
            <h6 className="mb-0">Outage Management</h6>
          </div>
          <div className="col-md-4 text-right">
          </div>
        </div>
        <hr />
      </div>
      <div className="row">

        <div className="col-sm-2">
          <input
            type="date"
            className="form-control mb-3"
            placeholder="Select Date"
            value={SelectedDate}
            min={MINDATE}
            max={MAXDATE}
            onChange={(e) => {
              setSelectedDate(e.target.value)
              setDevicesPopData([])
            }}
          />
        </div>

        {(UserGroup.Id === 1 || UserGroup.Id === 2) ?
          <div className="col-sm-2">
            {CUSTOM_CLIENT_SELECT({ ClientListOptions: ClientList, Value: getClientValue(), OnchangeClient: OnchangeClient })}
          </div>
          : null}
        {((UserGroup.Id === 1 || UserGroup.Id === 2 || UserGroup.Id === 3) && CountryList.length > 0 && ClientID) ?
          <div className="col-sm-2">
            <select
              className="form-select mb-3"
              onChange={(e) => {
                clearSearchHandler()
                setCountryID(e.target.value)
                setServiceCall(true);
              }}
              value={CountryID}
            >
              <option value="">Select Country</option>
              {
                CountryList.map((item, index) => (
                  <option key={index} value={item.Id}>
                    {item.CountryCode ?
                      <ReactCountryFlag
                        className="add_country_flag mx-5"
                        countryCode={item.CountryCode}
                        aria-label={item.CountryCode}
                        style={{
                        }}
                      />
                      : <i
                        className="bx bx-flag mx-1 p-0"
                        title="Country"
                        style={{ fontSize: "21px", cursor: "pointer" }}
                      ></i>
                    }
                    &nbsp;&nbsp;&nbsp;{item.CountryName}
                  </option>
                ))
              }
            </select>
          </div>
          : null}
        {
          !ChannelPartnersSelected || UserGroup.Id === 1 || UserGroup.Id === 2 || UserGroup.Id === 3 || UserGroup.Id === 4 || UserGroup.Id === 5 || UserGroup.Id === 6 ?
            CountryID !== '' ? <div className="col-sm-2">
              <select className="form-select" value={ChannelPartnersSelected} onChange={(e) => {
                setDeviceTypesSelected('')
                setDeviceTypesGroupsSelected('')
                setRegionsSelected('')
                setChannelPartnersSelected(e.target.value)
                setPage(1)
              }}>
                <option value="">Select Channel Partner</option>
                {
                  ChannelPartners.map((item, index) => {
                    return <option key={index} value={item}>{item}</option>
                  })
                }
              </select>
            </div> : null : null
        }
        {
          CountryID !== '' ? <div className="col-sm-2">
            <select
              className="form-select mb-3"
              onChange={(e) => {
                setRegionsSelected(e.target.value)
                setServiceCall(true)
              }}
              value={RegionsSelected}
            >
              <option value="">Select Regions</option>
              {
                Regions.map((item, index) => (
                  <option key={index} value={item}> {item} </option>
                ))
              }
            </select>
          </div> : null
        }
        {
          CountryID !== '' && ChannelPartnersSelected === "Fidelity ADT" ? <div className="col-sm-2">
            <select
              className="form-select mb-3"
              onChange={(e) => {
                setDeviceTypesSelected('')
                setDeviceTypesGroupsSelected(e.target.value)
                setServiceCall(true)
              }}
              value={DeviceTypesGroupsSelected}
            >
              <option value="">Select Device Type Group</option>
              {
                DeviceTypesGroups.map((item, index) => (
                  <option key={index} value={item}> {item} </option>
                ))
              }
            </select>
          </div> : null
        }

      </div>
      <div className='row'>
        <div className='col-sm-9'>
        </div>
        <div className="col-sm-3 row" style={{ textAlign: "right" }}>
          <div className='col-sm-10'>
            {
              CountryID !== '' && DeviceTypesGroupsSelected && ChannelPartnersSelected ?
                <select
                  className="form-select mb-3"
                  onChange={(e) => {
                    setDeviceTypesSelected(e.target.value)
                    setServiceCall(true)
                  }}
                  value={DeviceTypesSelected}
                >
                  <option value="">Select Device Type</option>
                  {
                    DeviceTypes.map((item, index) => (
                      <option key={index} value={item}> {item} </option>
                    ))
                  }
                </select>
                : null
            }
          </div>
          <div className='col-sm-2'>
            {
              searchInput || RegionsSelected || DeviceTypesSelected || DeviceTypesGroupsSelected || ChannelPartnersSelected ? <button
                type="button"
                className="btn btn-primary mb-3"
                title="Clear"
                onClick={clearSearchHandler}
              >
                <i className="bx bx-eraser search-icon"></i>
              </button> : null
            }
          </div>
        </div>

      </div>

      {
        ClientID > 0 && CountryID > 0 ?
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-4 px-5">
                  <ul className="nav nav-tabs outage_nav_tabs" >
                    <li className="nav-item" onClick={() => setActiveTab('Page1')} >
                      <p className={(ActiveTab === "Page1" ? " nav-link active p-active" : "nav-link p")} aria-current="page" >Page 1</p>
                    </li>

                    <li className="nav-item" onClick={() => setActiveTab('Page2')} >
                      <p className={(ActiveTab === "Page2" ? " nav-link active p-active" : "nav-link p")} aria-current="page" >Page 2</p>
                    </li>
                  </ul>
                </div>



                <div className={"col-md-2 p-2 mx-1 text-center tab-border my-1 d-flex align-items-center justify-content-center border-secondary"}>
                  Total Impacted Sites: <b>{TotalImpactedSites}</b>
                </div>

                <div className={"col-md-2 p-2 mx-1 text-center tab-border my-1 d-flex align-items-center justify-content-center border-secondary"}>
                  Total Impacted Basestations: <b>{TotalImpactedBasestations}</b>
                </div>

                <div className={"col-md-2 p-2 mx-1 text-center tab-border my-1 d-flex align-items-center justify-content-center border-secondary"}>
                  Total Impacted Devices: <b>{TotalImpactedDevices}</b>
                </div>

                <div className={"col-md-1 pt-0 mx-1 d-flex align-items-center justify-content-center"}>
                  {
                    ActiveTab === 'Page1' ? <button
                      type="button"
                      className="btn btn-primary m-0"
                      title="Export"
                      onClick={ExportEndCustomerDevices}
                      style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 20px', float: 'right' }}
                    >
                      Export
                    </button> : null
                  }
                  {
                    ActiveTab === 'Page2' ? <button
                      type="button"
                      className="btn btn-primary m-0"
                      title="Export"
                      onClick={ExportInternalDevices}
                      style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 20px', float: 'right' }}
                    >
                      Export
                    </button> : null
                  }
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-12">
                  {(ActiveTab === "Page1") ? <Page1
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    OutageData={Page1Data}
                    setServiceCall={setServiceCall}
                    clearSearchHandler={clearSearchHandler}

                    TotalImpactedDevices={TotalImpactedDevices}
                    TotalImpactedBasestations={TotalImpactedBasestations}
                    DeviceTypes={DeviceTypes}
                    Regions={Regions}
                    RegionsSelected={RegionsSelected}
                    HandleRigion={HandleRigion}
                    DeviceTypesSelected={DeviceTypesSelected}
                    HandleDeviceTypes={HandleDeviceTypes}

                    NoOfRecords={NoOfRecords}
                    page={page}
                    limit={limit}
                  /> : null}

                  {(ActiveTab === "Page2") ? <Page2
                    MapData={MapData}
                    setBaseStationIdNavigation={setBaseStationIdNavigation}
                    setMapData={setMapData}
                    TicketsModuleAccess={TicketsModuleAccess}
                    setSiteName={setSiteName}
                    CountryID={CountryID}
                    ServiceCall={ServiceCall}
                    OutageData={OutageData}
                    setOutageData={setOutageData}
                    SiteWiseCounts={SiteWiseCounts}
                    TotalCount={TotalCount}
                    TotalOtherCount={TotalOtherCount}
                    TotalStationaryCount={TotalStationaryCount}

                    DeviceTypes={DeviceTypes}
                    Regions={Regions}
                    RegionsSelected={RegionsSelected}
                    HandleRigion={HandleRigion}
                    DeviceTypesSelected={DeviceTypesSelected}
                    HandleDeviceTypes={HandleDeviceTypes}
                    FetchDevicesPopData={FetchDevicesPopData}

                    DevicesPopData={DevicesPopData}
                    setDevicesPopData={setDevicesPopData}
                    Filter={Filter}
                    setFilter={setFilter}
                    SiteSelected={SiteSelected}
                    setSiteSelected={setSiteSelected}

                    ExportInternalDevices={ExportInternalDevices}
                    ExportInternalDevicesDt={ExportInternalDevicesDt}
                  /> : null}
                </div>
              </div>

            </div>
          </div> : null
      }
    </>
  )
}

export default OutageList