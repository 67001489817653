import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { Link } from "react-router-dom";
import Alert from "../../Components/Alert";
import Loader from "../../Components/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import DefaultLogo from "../../Images/foxilogic-disable.png"
import Pagination from "../../Components/Pagination";
// import BulkUploadPopUp from "./BulkUploadPopUp";

function UsersList() {
  const navigate = useNavigate();
  const location = useLocation();

  var url = new URL(window.location.href);
  const page = url.searchParams.get('PageNo') || 1
  const limit = url.searchParams.get('Limit') || 100

  const [NoOfRecords, setNoOfRecords] = useState(0);
  const [ModuleData, setModuleData] = useState([]);
  const [ServiceCall, setServiceCall] = useState(true);
  const [DeleteUserId, setDeleteUserId] = useState("");
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState("");
  const [search, setSearch] = useState("")
  // const [BulkUploadFlag, setBulkUploadFlag] = useState(false)

  useEffect(() => {
    setServiceCall(true);
  }, [page, limit])

  useEffect(() => {
    if (location.state) {
      setSuccess(location.state);
      navigate("/users", {
        state: null,
      });
    }
  }, [location, navigate]);

  useEffect(() => {
    if (success) {
      toast.success(success);
      setSuccess("");
    }
    return () => { };
  }, [success]);

  useEffect(() => {
    if (ServiceCall) {
      setLoader(true);
      axios
        .get("users?Search=" + search + "&PageNo=" + page + "&Limit=" + limit)
        .then((response) => {
          setModuleData(response.data.data);
          setNoOfRecords(response.data.NoOfRecords)
          setServiceCall(false);
          setLoader(false);
        }) 
        .catch(() => {
          setLoader(false);
        });
    }
  }, [ServiceCall, search, page, limit]);

  const AlertResponse = (response) => {
    if (response === true) {
      setLoader(true);
      axios
        .delete("user/" + DeleteUserId)
        .then((response) => {
          setServiceCall(true);
          setDeleteUserId("");
          setSuccess("User deleted successfully");
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    } else {
      setDeleteUserId("");
    }
  };

  const handleSubmit = () => {
    setServiceCall(true)
  }

  const handleClearSearch = () => {
    setSearch("")
    setServiceCall(true)
  }

  const ExportUser = () => {
    setLoader(true)
    let url = "exportusers?Search=" + search
    axios.get(url)
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false)
        setSuccess(response.data.status.message)
      })
      .catch((error) => {
        setLoader(false)
      })
  }

  return (
    <>
      <ToastContainer theme="colored" />
      {loader ? <Loader /> : null}
      {DeleteUserId ? (
        <Alert
          message="Are you sure, you want to delete this user?"
          AlertResponse={AlertResponse}
        />
      ) : (
        ""
      )}
      {/* {BulkUploadFlag ? <BulkUploadPopUp setBulkUploadFlag={setBulkUploadFlag} /> : null} */}
      <div className="row">
        <div className="col-md-7">
          <h6 className="mb-0">Users List</h6>

        </div>
        <div className="col-md-5 text-right">
          <Link to="/add-user" className="btn btn-primary">
            <i className="bx bx-plus"></i> Add User
          </Link>
          {/* <button className="btn btn-primary mx-2" onClick={() => {
            setBulkUploadFlag(true);
          }}>
            <i className="bx bx-plus"></i> Bulk Upload
          </button> */}
        </div>
      </div>
      <hr />

      <div className="row">
        <div className="col-sm-3">
          <input
            type="text"
            className="form-control mb-3"
            id="search"
            name="search"
            placeholder="Search Here"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="col-sm-3">
          <button
            type="submit"
            className="btn btn-primary mb-3"
            title="Search"
            onClick={handleSubmit}
          >
            <i className="bx bx-search search-icon"></i>
          </button>
          &nbsp;
          {search ?
            <button
              type="button"
              className="btn btn-primary mb-3"
              title="Clear"
              onClick={handleClearSearch}
            >
              <i className="bx bx-eraser search-icon"></i>
            </button>
            : null}
        </div>
        <div className="col-sm-6 text-right">
          <button onClick={() => ExportUser()} type="button" title="Export" className="btn btn-primary" style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 20px' }}>Export</button>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div style={{ maxHeight: '72vh' }} className="horizontal-scroll">
            <table className="table mb-0 table-striped ticket-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th style={{ textAlign: "center" }}>Profile Image</th>
                  <th>Name</th>
                  <th>Surname</th>
                  <th>Username</th>
                  <th>Email</th>
                  <th>User Group</th>
                  <th>Role</th>
                  <th>Client</th>
                  <th>Service Provider</th>
                  <th>Activated</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {ModuleData.length > 0 ?
                  ModuleData.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{((page - 1) * limit) + index + 1}</td>
                        <td style={{ textAlign: "center" }}>
                          <img
                            src={item.ProfileImage ? item.ProfileImage : DefaultLogo}
                            className="user-img"
                            alt="User Profile"
                          />
                        </td>
                        <td style={{ verticalAlign: "middle" }}>{item.Name}</td>
                        <td style={{ verticalAlign: "middle" }}>{item.Surname}</td>
                        <td style={{ verticalAlign: "middle" }}>{item.Username}</td>
                        <td style={{ verticalAlign: "middle" }}>{item.Email ? item.Email : "-"}</td>
                        <td style={{ verticalAlign: "middle" }}>{item.usergroup ? item.usergroup.Name : ""}</td>
                        <td style={{ verticalAlign: "middle" }}>{item.role ? item.role.RoleName : ""}</td>
                        <td>{item.client ?
                          <>
                            <img
                              src={item.client.ClientLogo ? item.client.ClientLogo : DefaultLogo}
                              className="user-img"
                              alt="Client Logo"
                            />
                            &nbsp;&nbsp;&nbsp;{item.client.Name}
                          </>
                          : ""}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {item.custom_sp ? item.custom_sp.CustomSp : "-"}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <i className={parseInt(item.Activated) === 1 ? "bx bx-check text-success cursor-pointer" : "bx bx-x text-danger cursor-pointer"} style={{ fontSize: "27px" }} title={parseInt(item.Activated) === 1 ? "Activated" : "User is not activated."}></i>
                        </td>
                        <td className="action-buttons">
                          <i
                            className="bx bx-trash text-danger"
                            title="Delete"
                            onClick={() => {
                              setDeleteUserId(item.Id);
                            }}
                          ></i>
                          <Link to={"/add-user/" + item.Id}>
                            <i className="bx bx-pencil box-icon-style" title="Edit"></i>
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                  :
                  <tr>
                    <td
                      colSpan={12}
                      style={{ textAlign: "center" }}>
                      <b>No Record Found</b>
                    </td>
                  </tr>}
              </tbody>
            </table>
          </div>
          {
            NoOfRecords > 0 ?
              <Pagination
                records={ModuleData.length}
                link={"users"}
                SelectedPage={page}
                search={''}
                NoOfRecords={NoOfRecords}
              /> : null
          }
        </div>
      </div>
    </>
  );
}

export default UsersList;
