import React, { useEffect, useState } from "react";
import Login from "./Authorization/Login";

import Home from "./Container/Dashboard/Home";
import Home2 from "./Container/Dashboard2/Home";

import Logout from "./Logout";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { token } from "./features/TokenSlice";
import Template from "./Components/Template";
import UsersList from "./Container/Users/UsersList";
import AddUser from "./Container/Users/AddUser";
import axios from "./axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RoleList from "./Container/RoleManagement/RoleList";
import AddRole from "./Container/RoleManagement/AddRole";
import ClientList from "./Container/Clients/ClientList";
import AddClient from "./Container/Clients/AddClient";
import IncidentCodeConfig from "./Container/IncidentCodeManagement/IncidentCodeManagementList";
import SeverityList from "./Container/ManageSeverity/SeverityList";
import AddSeverity from "./Container/ManageSeverity/AddSeverity";
import ClientSeverityList from "./Container/ClientSeverity/ClientSeverityList"
import AddClientSeverity from "./Container/ClientSeverity/AddClientSeverity"
import TicketList from "./Container/Tickets/TicketsList"
import RecommendedFixList from "./Container/RecommendedFix/RecommendedFixList";
import ViewTickets from "./Container/Tickets/ViewTickets";
import ViewSiteStatusConfig from "./Container/SiteStatusConfig/ViewSiteStatusConfig";
import ViewDynamicSLAConfig from "./Container/DynamicSLAConfig/ViewDynamicSLAConfig";
import AddDynamicSLA from "./Container/ManageDynamicSLA/AddDynamicSLA";
import ViewDynamicSLA from "./Container/ManageDynamicSLA/ViewDynamicSLA";
import ViewFlutteringConfig from "./Container/Fluttering/ViewFlutteringConfig";
import MerakiFlutteringConfig from "./Container/Fluttering/MerakiFlutteringConfig";
import { timezone } from "./features/TimeZoneSlice";
import ViewTicketAutoCloseConfig from "./Container/TicketAutoClose/ViewTicketAutoCloseConfig";
import SendEmail from "./Container/Tickets/SendEmail";
import EmailList from "./Container/SentEmails/EmailList";
import OutageList from "./Container/OutageManagement/OutageList";
import CabisenseViewTickets from "./Container/CabisenseTickets/CabisenseViewTickets";
import CabisenseTicketsList from "./Container/CabisenseTickets/CabisenseTicketsList";
import CabisenseSendEmail from "./Container/CabisenseTickets/CabisenseSendEmail";
import ViewCategories from "./Container/ManageIncidentCodeCategories/ViewCategories";
import AddCategories from "./Container/ManageIncidentCodeCategories/AddCategories";
import SiteHostManagement from "./Container/SiteHostManagement/SiteHostManagement";
import BsTicketTimeReport from "./Container/Reports/BsTicketTimeReport";
import CabisenseTicketTimeReport from "./Container/Reports/CabisenseTicketTimeReport";
import MultiBitList from "./Container/MultiBitIncidentCodeConfig/MultiBitList";
import AddMultiBit from "./Container/MultiBitIncidentCodeConfig/AddMultiBit";
import MaintenanceManagement from "./Container/MaintenanceManagement/MaintenanceManagement";
import BasestationDownConfigList from "./Container/BasestationDownConfig/List";
import BasestationDownConfigAddEdit from "./Container/BasestationDownConfig/AddEdit";
import ChannelPartnerGroupList from "./Container/ChannelPartnerGroupConfig/List";
import ChannelPartnerGroupAddEdit from "./Container/ChannelPartnerGroupConfig/AddEdit";

import ImpactedDeviceNotificationConfigList from "./Container/ImpactedDeviceNotificationConfig/List";
import ImpactedDeviceNotificationConfigAddEdit from "./Container/ImpactedDeviceNotificationConfig/AddEdit";
import MerakiViewTickets from "./Container/MerakiTickets/MerakiViewTickets";
import MerakiTicketsList from "./Container/MerakiTickets/MerakiTicketsList";
import MerakiSendEmail from "./Container/MerakiTickets/MerakiSendEmail";

import BasestationSpacificList from "./Container/BasestationSpecificConfig/List";
import BasestationSpacificAddEdit from "./Container/BasestationSpecificConfig/AddEdit";
import MerakiTicketTimeReport from "./Container/Reports/MerakiTicketTimeReport";

import CabisenseRecommendedFixList from "./Container/CabisenseRecommendedFix/List";
import MerakiRecommendedFixList from "./Container/MerakiRecommendedFix/List";
import CabisenseIncidentCodeList from "./Container/CabisenseIncidentCodeManagement/CabisenseIncidentCodeList";
import MerakiIncidentCodeList from "./Container/MerakiIncidentCodeList/List";
import ForgetPassword from "./Authorization/ForgetPassword";
import SignUp from "./Authorization/SignUp";
import ResetPassword from "./Authorization/ResetPassword";
import OtpVarification from "./Authorization/OtpVarification";
import EmailVarification from "./Authorization/EmailVarification";
import SigfoxAutoFaultWaitConfig from "./Container/SigfoxAutoFaultWaitConfig/List"
import SetUserPassword from "./Authorization/SetUserPassword";
import ClientCallbackSettings from "./Container/Clients/ClientCallbackSettings";
import CustomSpList from "./Container/CustomSpConfig/List";
import AddCustomSp from "./Container/CustomSpConfig/AddEdit";
import TicketDetails from "./Components/TicketDetails";
import SpTicketList from "./Container/Tickets/SpTicketsList"
import SpCabisenseTicketsList from "./Container/CabisenseTickets/SpCabisenseTicketsList";
import SpMerakiTicketsList from "./Container/MerakiTickets/SpMerakiTicketsList";
import List from "./Container/NetworkDashboard/List";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  useEffect(() => {
    if (error) {
      toast.error(error);
      setError("");
    }
    return () => { };
  }, [error]);

  useEffect(() => {
    if (localStorage.getItem("Token")) {
      dispatch(token(JSON.parse(localStorage.getItem("Token"))));
    }
  }, [dispatch]);

  useEffect(() => {
    if (localStorage.getItem("TimeZone")) {
      dispatch(timezone(JSON.parse(localStorage.getItem("TimeZone"))));
    }
  }, [dispatch]);

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // setError(error.response.data.status.message);
      // if (error.response.data.status.code) {
      //   // navigate("/logout");
      // }
      // return Promise.reject(error);

      if (parseInt(error.response.status) === 401) {
        const CurrentUrl = window.location.href;
        const ActiveUrlArr = CurrentUrl.split("/");
        if (ActiveUrlArr[ActiveUrlArr.length - 1] === "login") {
          setError(error.response.data.status.message);
        } else {
          setError(error.response.data.message);
          navigate("/logout");
        }
      } else {
        setError(error.response.data.status.message);
      }

      return Promise.reject(error);

    }
  );

  const tokenValue = useSelector((state) => state.token.value);

  var paths = null;
  if (tokenValue.token) {
    const FirstModuleLink = localStorage.getItem("FirstModuleLink");
    const Modules = JSON.parse(localStorage.getItem("Modules"))
    const UserGroup = localStorage.getItem("UserGroup") ? JSON.parse(localStorage.getItem("UserGroup")) : {};
    var DefaultRedirection = "";

    var MyTemplates = {
      'home': <Template><Home /></Template>,
      'home2': <Template><Home2 /></Template>,
      'network_dashboard': <Template><List /></Template>,
      'users': <Template><UsersList /></Template>,
      'clients': <Template><ClientList /></Template>,
      'role_management': <Template><RoleList /></Template>,
      'outage_management': <Template><OutageList /></Template>,
      'sent_emails': <Template><EmailList /></Template>,
      'tickets': <Template><ViewTickets /></Template>,
      'cabisense_tickets': <Template><CabisenseViewTickets /></Template>,
      'meraki_tickets': <Template><MerakiViewTickets /></Template>,
      'report/bs_ticket_time_report': <Template><BsTicketTimeReport /></Template>,
      'report/meraki_ticket_time_report': <Template><MerakiTicketTimeReport /></Template>,
      'incident_code_config': <Template><IncidentCodeConfig /></Template>,
      'cabisense_incident_code_config': <Template><CabisenseIncidentCodeList /></Template>,
      'meraki_incident_code_config': <Template><MerakiIncidentCodeList /></Template>,
      'recommended_fix_config': <Template><RecommendedFixList /></Template>,
      'cabisense_recommended_fix_config': <Template><CabisenseRecommendedFixList /></Template>,
      'meraki_recommended_fix_config': <Template><MerakiRecommendedFixList /></Template>,
      'manage_severity': <Template><SeverityList /></Template>,
      'client_severity': <Template><ClientSeverityList /></Template>,
      'site_status_config': <Template><ViewSiteStatusConfig /></Template>,
      'fluttering_config': <Template><ViewFlutteringConfig /></Template>,
      'close_waiting_config': <Template><ViewTicketAutoCloseConfig /></Template>,
      'dynamic_sla_config': <Template><ViewDynamicSLAConfig /></Template>,
      'manage_dynamic_sla': <Template><ViewDynamicSLA /></Template>,
      'manage_incident_code_categories': <Template><ViewCategories /></Template>,
      'site_host_management': <Template><SiteHostManagement /></Template>,
      'multi_bit_incident_code_config': <Template><MultiBitList /></Template>,
      'maintenance_management': <Template><MaintenanceManagement /></Template>,
      'channel_partner_group_config': <Template><ChannelPartnerGroupList /></Template>,
      'basestation_down_config': <Template><BasestationDownConfigList /></Template>,
      'basestation_specific_config': <Template><BasestationSpacificList /></Template>,
      'impacted_devices_notification_config': <Template><ImpactedDeviceNotificationConfigList /></Template>
    };

    if (JSON.stringify(UserGroup) !== "{}") {
      if ((UserGroup.Id === 5 || UserGroup.Id === 6) && Modules.length > 0) {
        if (UserGroup.Id === 5) {
          DefaultRedirection = <Template><SpTicketList /></Template>
        } else {
          DefaultRedirection = <Template><TicketList /></Template>
        }
      } else if (MyTemplates[FirstModuleLink] !== undefined) {
        DefaultRedirection = MyTemplates[FirstModuleLink];
      } else {
        DefaultRedirection = <Template></Template>
      }
    }

    var TicketTemplate
    var CabisenseTicketTemplate
    var MerakiTicketTemplate

    if (UserGroup.Id === 5) {
      TicketTemplate = <Template><SpTicketList /></Template>;
      CabisenseTicketTemplate = <Template><SpCabisenseTicketsList /></Template>
      MerakiTicketTemplate = <Template><SpMerakiTicketsList /></Template>
    } else {
      TicketTemplate = <Template><TicketList /></Template>;
      CabisenseTicketTemplate = <Template><CabisenseTicketsList /></Template>
      MerakiTicketTemplate = <Template><MerakiTicketsList /></Template>
    }

    paths = (
      <Routes>

        {/* Logout */}
        <Route exact path="/logout" element={<Logout />}></Route>

        {/* Dashboard */}
        <Route exact path="/home" element={<Template><Home /></Template>}></Route>
        <Route exact path="/home2" element={<Template><Home2 /></Template>}></Route>
        <Route exact path="/network_dashboard" element={<Template><List /></Template>}></Route>

        {/*  User Management*/}
        <Route exact path="/users" element={<Template><UsersList /></Template>}></Route>
        <Route exact path="/add-user" element={<Template><AddUser /></Template>}></Route>
        <Route exact path="/add-user/:id" element={<Template><AddUser /></Template>}></Route>

        {/* Role Management */}
        <Route exact path="/role_management" element={<Template><RoleList /></Template>}></Route>
        <Route exact path="/add_role" element={<Template><AddRole /></Template>}></Route>
        <Route exact path="/add_role/:id" element={<Template><AddRole /></Template>}></Route>

        {/* Clients */}
        <Route exact path="/clients" element={<Template><ClientList /></Template>}></Route>
        <Route exact path="/add_clients" element={<Template><AddClient /></Template>}></Route>
        <Route exact path="/add_clients/:id" element={<Template><AddClient /></Template>}></Route>
        <Route exact path="/client_callback_settings" element={<Template><ClientCallbackSettings /></Template>}></Route>

        {/* Outage management */}
        <Route exact path="/outage_management" element={<Template><OutageList /></Template>}></Route>

        {/* Sent Email Module */}
        <Route exact path="/send_email/:id" element={<Template><SendEmail /></Template>}></Route>
        <Route exact path="/sent_emails" element={<Template><EmailList /></Template>}></Route>

        {/*Sigfox Tickets */}
        <Route exact path="/tickets" element={<Template><ViewTickets /></Template>}></Route>
        <Route exact path="/tickets-list/:CountryID" element={TicketTemplate}></Route>

        {/* Cabisense Tickets */}
        <Route exact path="/cabisense_tickets" element={<Template><CabisenseViewTickets /></Template>}></Route>
        <Route exact path="/cabisense-tickets-list/:CountryID" element={CabisenseTicketTemplate}></Route>
        <Route exact path="/cabisense_send_email/:id" element={<Template><CabisenseSendEmail /></Template>}></Route>

        {/* Meraki Tickets */}
        <Route exact path="/meraki_tickets" element={<Template><MerakiViewTickets /></Template>}></Route>
        <Route exact path="/meraki-tickets-list/:CountryID" element={MerakiTicketTemplate}></Route>
        <Route exact path="/meraki_send_email/:id" element={<Template><MerakiSendEmail /></Template>}></Route>

        {/* MTTR Reports */}
        <Route exact path="/report/bs_ticket_time_report" element={<Template><BsTicketTimeReport /></Template>}></Route>
        <Route exact path="/report/cabisense_ticket_time_report" element={<Template><CabisenseTicketTimeReport /></Template>}></Route>
        <Route exact path="/report/meraki_ticket_time_report" element={<Template><MerakiTicketTimeReport /></Template>}></Route>

        {/* CONFIGURATION START */}

        {/* Sigfox Incident Code Configuaration */}
        <Route exact path="/incident_code_config" element={<Template><IncidentCodeConfig /></Template>}></Route>

        {/* Cabisense Incident Code Configuration */}
        <Route exact path="/cabisense_incident_code_config" element={<Template><CabisenseIncidentCodeList /></Template>}></Route>

        {/* Meraki Incident Code Configuration */}
        <Route exact path="/meraki_incident_code_config" element={<Template><MerakiIncidentCodeList /></Template>}></Route>

        {/* Sigfox Recommentded Fix Configuration */}
        <Route exact path="/recommended_fix_config" element={<Template><RecommendedFixList /></Template>}></Route>

        {/* Cabisense Recommended Fix Configuration */}
        <Route exact path="/cabisense_recommended_fix_config" element={<Template><CabisenseRecommendedFixList /></Template>}></Route>

        {/* Meraki Recommended Fix Configuration */}
        <Route exact path="/meraki_recommended_fix_config" element={<Template><MerakiRecommendedFixList /></Template>}></Route>

        {/* Manage Severity Configuration */}
        <Route exact path="/manage_severity" element={<Template><SeverityList /></Template>}></Route>
        <Route exact path="/add_severity" element={<Template><AddSeverity /></Template>}></Route>
        <Route exact path="/add_severity/:id" element={<Template><AddSeverity /></Template>}></Route>

        {/* Client Severity Configuration */}
        <Route exact path="/client_severity" element={<Template><ClientSeverityList /></Template>}></Route>
        <Route exact path="/add_client_severity" element={<Template><AddClientSeverity /></Template>}></Route>
        <Route exact path="/add_client_severity/:id" element={<Template><AddClientSeverity /></Template>}></Route>

        {/* Site Status Configuaration */}
        <Route exact path="/site_status_config" element={<Template><ViewSiteStatusConfig /></Template>}></Route>

        {/* Fluttering Configuration */}
        <Route exact path="/fluttering_config" element={<Template><ViewFlutteringConfig /></Template>}></Route>
        <Route exact path="/meraki_fluttering_config" element={<Template><MerakiFlutteringConfig /></Template>}></Route>

        {/* Close Waiting Configuration */}
        <Route exact path="/close_waiting_config" element={<Template><ViewTicketAutoCloseConfig /></Template>}></Route>

        {/* Dynamic Sla Configuration */}
        <Route exact path="/dynamic_sla_config" element={<Template><ViewDynamicSLAConfig /></Template>}></Route>
        <Route exact path="/add_dynamic" element={<Template><AddDynamicSLA /></Template>}></Route>
        <Route exact path="/add_dynamic/:dynamicID" element={<Template><AddDynamicSLA /></Template>}></Route>
        <Route exact path="/manage_dynamic_sla" element={<Template><ViewDynamicSLA /></Template>}></Route>

        {/* Incident Code Categories Configuartion */}
        <Route exact path="/manage_incident_code_categories" element={<Template><ViewCategories /></Template>}></Route>
        <Route exact path="/add_categories/:category_id" element={<Template><AddCategories /></Template>}></Route>
        <Route exact path="/add_categories" element={<Template><AddCategories /></Template>}></Route>

        {/* Power Confirmation Configuartion */}
        <Route exact path="/site_host_management" element={<Template><SiteHostManagement /></Template>}></Route>

        {/* Multi Bit Configuration */}
        <Route exact path="/multi_bit_incident_code_config" element={<Template><MultiBitList /></Template>}></Route>
        <Route exact path="/add_multi_bit_incident_code_config" element={<Template><AddMultiBit /></Template>}></Route>
        <Route exact path="/update_multi_bit_incident_code_config/:id" element={<Template><AddMultiBit /></Template>}></Route>

        {/* Maintenance SP IMS Configuration */}
        <Route exact path="/maintenance_management" element={<Template><MaintenanceManagement /></Template>}></Route>

        {/* Channel Partner Group Configuration */}
        <Route exact path="/channel_partner_group_config" element={<Template><ChannelPartnerGroupList /></Template>}></Route>
        <Route exact path="/add_channel_partner_group_config" element={<Template><ChannelPartnerGroupAddEdit /></Template>}></Route>
        <Route exact path="/add_channel_partner_group_config/:GroupID" element={<Template><ChannelPartnerGroupAddEdit /></Template>}></Route>

        {/* Base Station Down (Generic) Configuration */}
        <Route exact path="/basestation_down_config" element={<Template><BasestationDownConfigList /></Template>}></Route>
        <Route exact path="/add_basestation_down" element={<Template><BasestationDownConfigAddEdit /></Template>}></Route>
        <Route exact path="/add_basestation_down/:id" element={<Template><BasestationDownConfigAddEdit /></Template>}></Route>

        {/* Basestation Down (Specific) Configuration */}
        <Route exact path="/basestation_specific_config" element={<Template><BasestationSpacificList /></Template>}></Route>
        <Route exact path="/add_basestation_specific_config/:ClientId/:CountryId/:Id" element={<Template><BasestationSpacificAddEdit /></Template>}></Route>
        <Route exact path="/add_basestation_specific_config/:ClientId/:CountryId" element={<Template><BasestationSpacificAddEdit /></Template>}></Route>

        {/* Impacted Devices Notification */}
        <Route exact path="/add_impacted_devices_notification_config" element={<Template><ImpactedDeviceNotificationConfigAddEdit /></Template>}></Route>
        <Route exact path="/add_impacted_devices_notification_config/:Id" element={<Template><ImpactedDeviceNotificationConfigAddEdit /></Template>}></Route>
        <Route exact path="/impacted_devices_notification_config" element={<Template><ImpactedDeviceNotificationConfigList /></Template>}></Route>

        {/* Client Create Fault Waiting Configuration */}
        <Route exact path="/sigfox_auto_fault_wait_config" element={<Template><SigfoxAutoFaultWaitConfig /></Template>}></Route>


        {/* Custom SP */}
        {/* Basestation Down (Specific) Configuration */}
        <Route exact path="/custom_sp_config" element={<Template><CustomSpList /></Template>}></Route>
        <Route exact path="/add_custom_sp_config" element={<Template><AddCustomSp /></Template>}></Route>
        <Route exact path="/edit_custom_sp_config/:Id" element={<Template><AddCustomSp /></Template>}></Route>

        {/* CONFIGURATION END */}

        {/* Ticket Details */}
        <Route exact path="ticket-details/:token" element={<Template><TicketDetails /></Template>}></Route>
        <Route exact path="cabisense-ticket-details/:token" element={<Template><TicketDetails /></Template>}></Route>
        <Route exact path="meraki-ticket-details/:token" element={<Template><TicketDetails /></Template>}></Route>

        <Route exact path="set_user_password/:token" element={<SetUserPassword />}></Route>
        <Route path="*" element={DefaultRedirection}></Route>

      </Routes>
    );
  } else {
    paths = (
      <Routes>
        <Route exact path="/login" element={<Login />}></Route>
        <Route exact path="/forget_password" element={<ForgetPassword />}></Route>
        <Route exact path="/sign_up" element={<SignUp />}></Route>
        <Route exact path="/reset_password" element={<ResetPassword />}></Route>
        <Route exact path="/opt_varification" element={<OtpVarification />}></Route>
        <Route exact path="/email_varification" element={<EmailVarification />}></Route>
        <Route exact path="set_user_password/:token" element={<SetUserPassword />}></Route>
        <Route exact path="*" element={<Login />}></Route>
      </Routes>
    );
  }
  return (
    <div>
      <ToastContainer theme="colored" />
      {paths}
    </div>
  );
}

export default App;
